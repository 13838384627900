<template>
  <div class="content">
    <NavBar :title="btnText" :backColor="'ed4e03'"></NavBar>
    <div class="kong"></div>
    <div class="content-com">
      <div class="title" v-if="type === 'out'">
        {{ $t('ganme.transferGameBalanceAccount') }}
      </div>
      <div class="balance-com" v-else>
        <!-- <img
          :src="require('@/assets/images/amount_orange_rmb_icon.png')"
          alt="icon"
        /> -->
        <div>
          <span>{{ $t('home.accountBalance') }}</span>
          <p>{{ $money(moneyForm.money) }}</p>
        </div>
      </div>
      <div class="input-money-com">
        <span>{{ verConfig.moneyType }}</span>
        <input type="number" v-model="moneyVal" :placeholder="placeholder" />
        <p v-if="type !== 'out'" @click="handleClickAllMoney">{{ $t('home.all') }}</p>
      </div>
    </div>
    <button class="commit-btn" @click="handleCommit">{{ btnText }}</button>
  </div>
</template>

<script>
import NavBar from '@/components/Navbar'
import request from '@/utils/request'
export default {
  name: '',
  components: { NavBar },
  data() {
    return {
      type: '',
      moneyVal: '',
      moneyForm: {},
      btnText: '',
      placeholder: '0.00',
    }
  },
  mounted() { },
  created() {
    this.type = this.$route.query.type
    this.btnText = this.type === 'out' ? this.$t('game.moveAway') : this.$t('game.transferToAccountTwo')
    this.getUserBalance()
  },
  methods: {
    /* HANDLE */
    handleClickAllMoney() {
      this.moneyVal = this.moneyForm.money
    },
    handleCommit() {
      // 从游戏转回用户余额：/api/obgame/qm/withdraw
      // 从余额转入到游戏：/api/obgame/qm/deposit
      if (!this.moneyVal) {
        this.$toast(this.$t('home.pleaseEnterAmount'))
        return false
      }
      let url = this.type === 'out' ? 'obgame/qm/withdraw' : 'obgame/qm/deposit'
      this.moneyTabChange(url)
    },
    /* API */
    async getUserBalance() {
      this.$toast.loading({ forbidClick: true, loadingType: 'spinner' })
      const {
        data: { data, ret },
      } = await request({
        method: 'POST',
        url: 'obgame/qm/balance',
      })
      this.$toast.clear()
      if (ret === 1) {
        this.moneyForm = data
        this.moneyVal = ''
        if (this.type === 'out') {
          if (this.moneyForm.bal) {
            this.placeholder = (this.moneyForm.bal * 1).toFixed(2)
          } else {
            this.placeholder = '0.00'
          }
        }
      } else {
        this.moneyForm = {}
        this.placeholder = '0.00'
      }
    },
    async moneyTabChange(url) {
      this.$toast.loading({ forbidClick: true, loadingType: 'spinner' })
      const {
        data: { data, ret },
      } = await request({
        method: 'POST',
        url,
        data: {
          money: this.moneyVal,
        },
      })
      this.$toast.clear()
      if (ret === 1) {
        this.$toast(this.$t('game.promotionSuccessful'))
        this.getUserBalance()
      } else {
        this.$toast(data.msg)
      }
    },
  },
}
</script>

<style scoped lang="less" >
.content {
  /deep/ .van-nav-bar__content {
    background: #ed4e03;

    .iconfont {
      font-size: 44px;
      color: #fff;
    }
  }

  >.kong {
    height: 100px;
  }

  >.content-com {
    >.title {
      color: #999999;
      font-size: 28px;
      margin: 26px 30px 30px;
    }

    >.input-money-com {
      padding: 120px 0 40px;
      background-color: #fff;
      display: flex;
      align-items: center;

      >span {
        font-size: 72px;
        color: #333333;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        margin-top: 10px;
      }

      >input {
        border: none;
        width: 500px;
        height: 100px;
        font-size: 72px;
        font-weight: 400;
        color: #333333;
      }

      >p {
        color: #e04801;
        font-size: 28px;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
      }
    }

    >.balance-com {
      background-color: white;
      height: 128px;
      border-bottom-color: #e5e5e5;
      border-bottom-width: 1px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      >img {
        width: 58px;
        height: 58px;
        margin-left: 30px;
      }

      >div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 30px;

        >span {
          color: #333333;
          font-size: 30px;
        }

        >p {
          color: #999999;
          font-size: 28px;
          margin-top: 10px;
        }
      }
    }
  }

  >.commit-btn {
    width: 690px;
    margin-left: 30px;
    height: 90px;
    background-color: #fb6a25;
    color: white;
    font-size: 36px;
    text-align: center;
    line-height: 90px;
    border-radius: 10px;
    margin-top: 80px;
    border: none;
  }
}
</style>
